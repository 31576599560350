import React from 'react'
import { makeStyles } from '@material-ui/core'

import Header from '../header/index'
import Footer from '../footer/index'

const useStyles = makeStyles(theme => ({
    MainContainer: {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
    }
}))

export default function Wrapper(props) {
    const classes = useStyles()
    return (
        <>
            <div className={classes.MainContainer}>
                <Header />
                <main>
                    {props.children}
                </main>
                <Footer />
            </div>
        </>
    )
}