const { responsiveFontSizes, unstable_createMuiStrictModeTheme } = require("@material-ui/core")

const typography = {
    fontFamily: `'Poppins', sans-serif`,
    h1: {
        fontWeight: "bold"
    },
    h2: {
        fontWeight: "bold"
    },
    h3: {
        fontWeight: "bold"
    },
    h4: {
        fontWeight: "bold"
    },
    h5: {
        fontWeight: "bold"
    },
    h6: {
        fontWeight: "bold"
    },
    body1: {
        fontFamily: "'Noto Sans', sans-serif"
    },
    body2: {
        fontFamily: "'Noto Sans', sans-serif",
        fontSize: ".8rem",
        letterSpacing: "0.0075em",
        lineHeight: 1.6
    },
    subtitle1: {
        fontFamily: "'Noto Sans', sans-serif",
        fontSize: "0.875rem",
        lineHeight: 1.25
    },
    subtitle2: {
        fontFamily: "'Noto Sans', sans-serif",
        fontSize: "0.675rem"
    }
}

const theme = {
    typography
}

export default responsiveFontSizes(unstable_createMuiStrictModeTheme(theme))
