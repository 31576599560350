import React, { useEffect, useRef, useState } from 'react'

import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    fade,
    Fade,
    Grid,
    GridList,
    GridListTile,
    GridListTileBar,
    makeStyles,
    Modal,
    Typography
} from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import StoreIcon from '@material-ui/icons/Store'
import CheckIcon from '@material-ui/icons/Check'
import { INDEX_HEADER } from '../../config/images'

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

import { firestore } from '../../config/firebase/index'
import Dotdotdot from 'react-dotdotdot'
import { grey } from '@material-ui/core/colors'
import { useTranslation } from 'react-i18next'


const useStyles = makeStyles(theme => ({
    MainContainer: {
    },
    HeaderContainer: {
        position: "relative",
        overflow: "hidden",
        background: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1123 258'><path d='M1124,2c0,0 0,256 0,256l-1125,0l0,-48c0,0 16,5 55,5c116,0 197,-92 325,-92c121,0 114,46 254,46c140,0 214,-167 572,-166Z' style='fill: hsla(0, 0%, 98%, 1)' /></svg>") no-repeat bottom, linear-gradient(to bottom, ${fade(grey[600], 0)} 0%, ${grey[300]} 100%)`,
        [theme.breakpoints.down('sm')]: {
            background: "none"
        }
    },
    HeaderImage: {
        padding: theme.spacing(6, 6, 6, 12),
        "& img": {
            boxShadow: theme.shadows[6]
        },
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    HeaderText: {
        padding: theme.spacing(6),
        color: theme.palette.getContrastText(theme.palette.background.default),
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2)
        }
    },
    SitenameText: {
        marginLeft: -4
    },
    DescriptionText: {
        fontSize: "1.4rem"
    },
    InformationBox: {
        padding: theme.spacing(4),
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        }
    },
    ServicesContainer: {
        marginBottom: theme.spacing(4)
    },
    CardContainer: {
        maxWidth: 1400,
        margin: "0 auto",
        padding: theme.spacing(2)
    },
    CardItem: {
        textAlign: "center"
    },
    IconContainer: {
        display: "flex",
        justifyContent: "center",
        "& svg": {
            fontSize: "6rem"
        },
        marginBottom: theme.spacing(4)
    },
    GridListContainer: {
        maxWidth: 1404,
        padding: theme.spacing(2),
        margin: "0 auto!important", // Overwrite Material-UI margin overwrite
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            "& .MuiGridListTile-root": {
                width: "50%!important"
            }
        }
    },
    ModalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    ModalDivContainer: {
        display: "flex",
        minWidth: "70vw",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        [theme.breakpoints.down('sm')]: {
            minWidth: "90vw"
        },
        [`${theme.breakpoints.down('sm')} and (orientation: landscape)`]: {
            minWidth: "100vh"
        }
    },
    ModalImageContainer: {
        position: "relative",
        paddingBottom: "56.2%"
    },
    ModalImage: {
        position: "absolute",
        objectFit: "scale-down",
        width: "100%",
        height: "100%"
    },
    ModalImageInformationContainer: {
        padding: theme.spacing(1),
        textAlign: "center",
    },
    ModalImageInformationDescription: {
        display: "block",
        whiteSpace: "pre-wrap",
        maxHeight: 36,
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: 4
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        }
    },
    ModalNavigationButtonContainer: {
        display: "flex",
        justifyContent: "center"
    }
}))

function HeaderPart() {
    const classes = useStyles()
    const { t } = useTranslation('common')

    return (
        <div className={classes.MainContainer}>
            <div className={classes.HeaderContainer}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <div className={classes.HeaderImage}>
                            <img src={INDEX_HEADER} alt="" width="100%" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                        <div className={classes.HeaderText}>
                            <Typography className={classes.SitenameText} variant="h1" component="h1" gutterBottom>
                                {t('site.title')}
                            </Typography>
                            <Typography className={classes.DescriptionText} variant="body1" component="p">
                                {t('site.description')}
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

function ServicesCard(props) {
    const { classes, icon, title, subtitle } = props

    return (
        <Grid item xs={12} md={12} lg={4}>
            <Card className={classes.CardItem} elevation={6}>
                <CardContent>
                    <div className={classes.IconContainer}>
                        {icon}
                    </div>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                    {subtitle ?
                        <Typography variant="body2">
                            {subtitle}
                        </Typography> :
                        ""}
                </CardContent>
            </Card>
        </Grid>
    )
}

function Services() {
    const classes = useStyles()
    const { t } = useTranslation('common')

    const ServicesList = [
        {
            title: t("services.slaughterhouse_equipment"),
            subtitle: "",
            icon: <BuildIcon />
        },
        {
            title: t("services.maintenance"),
            subtitle: "",
            icon: <StoreIcon />
        },
        {
            title: t("services.spare_part"),
            subtitle: "",
            icon: <CheckIcon />
        }
    ]

    return (
        <>
            <div className={classes.ServicesContainer}>
                <div className={classes.CardContainer}>
                    <Typography variant="h2" component="h5" align="center">
                        {t('ns.services')}
                    </Typography>
                    <Grid container spacing={4} justify="center">
                        {ServicesList.map(s => <ServicesCard key={s.title} classes={classes} {...s} />)}
                    </Grid>
                </div>
            </div>
        </>
    )
}

function ItemsPart() {
    const classes = useStyles()
    const { t } = useTranslation('common')
    const [itemsData, setItemsData] = useState([])
    const [itemsRawData, setItemsRawData] = useState(null)
    const [page, setPage] = useState(0)
    const [open, setOpen] = useState(false)
    const [activeImage, setActiveImage] = useState(null)
    const [itemsLoading, setItemsLoading] = useState(true)
    const [moreItemsLoading, setMoreItemsLoading] = useState(false)
    const lastItemOnThePage = useRef(null)

    useEffect(() => {
        let offset
        if (lastItemOnThePage.current) offset = lastItemOnThePage.current.offsetTop

        async function getDataFromDatabase() {
            setMoreItemsLoading(true)

            try {
                const itemLimit = 20

                let data
                if (page === 0) data = await firestore.collection("items").orderBy("updatedAt", "desc").limit(itemLimit).get()
                else {
                    data = await firestore.collection("items").orderBy("updatedAt", "desc").startAfter(itemsRawData.docs[itemsRawData.docs.length - 1]).limit(itemLimit).get()
                }
                const tempList = [...itemsData]

                data.forEach(item => {
                    tempList.push({ ...item.data(), id: item.id })
                })
                if (tempList.length !== (page + 1) * itemLimit || itemLimit > tempList.length) setItemsRawData(null)
                else setItemsRawData(data)

                setItemsData(tempList)
                setItemsLoading(false)
                setMoreItemsLoading(false)
            } catch (err) {
                setItemsLoading(false)
                setMoreItemsLoading(false)
            }
        }

        getDataFromDatabase()

        setTimeout(() => {
            if (offset) window.scrollTo(0, offset + 240 || 0)
        }, 500)
        // eslint-disable-next-line
    }, [page])

    function handleMoreItemsClick() {
        setPage(state => state + 1)
    }

    function handleModalClick(index) {
        if (index !== null) {
            setOpen(true)
            setActiveImage(index)
        }
        else {
            setActiveImage(null)
            setOpen(false)
        }
    }

    function handlePreviousButtonClick() {
        if (activeImage - 1 < 0) setActiveImage(itemsData.length - 1)
        else return setActiveImage(state => state - 1)
    }

    function handleNextButtonClick() {
        if (activeImage > itemsData.length - 2) setActiveImage(0)
        else return setActiveImage(state => state + 1)
    }

    const placeholderLink = "https://via.placeholder.com"

    return (
        <>
            {itemsData.length ?
                <Typography variant="h2" component="h5" align="center">
                    {t('ns.parts')}
                </Typography> : ""}
            <Box display="flex" justifyContent="center">
                {itemsLoading ?
                    <CircularProgress />
                    : ""}
            </Box>
            <GridList cellHeight={240} cols={4} className={classes.GridListContainer}>
                {itemsData.length ? itemsData.map((item, index) => (
                    <GridListTile key={item.id} onClick={() => handleModalClick(index)} ref={itemsData.length - 1 === index ? lastItemOnThePage : null}>
                        <img src={item.photo || `${placeholderLink}/300?text=?`} alt={item["photo-name"]} />
                        {item.title || item.description ?
                            <GridListTileBar
                                title={item.title ? item.title : ""}
                                subtitle={item.description ? <Dotdotdot clamp={1} useNativeClamp><span>{item.description}</span></Dotdotdot> : ""}
                            />
                            : ""}
                    </GridListTile>
                )) : ""}
            </GridList>
            <Box textAlign="center">
                {itemsRawData ? <Button onClick={handleMoreItemsClick} disabled={moreItemsLoading} >{moreItemsLoading ? <CircularProgress /> : t('ns.load_more')}</Button> : ""}
            </Box>
            {activeImage !== null ?
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.ModalContainer}
                    open={open}
                    onClose={() => handleModalClick(null)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.ModalDivContainer}>
                            <div className={classes.ModalImageContainer}>
                                <img src={itemsData[activeImage].photo || `${placeholderLink}/300?text=?`} alt="" className={classes.ModalImage} />
                            </div>
                            <div className={classes.ModalImageInformationContainer}>
                                {itemsData[activeImage].title ?
                                    <Typography variant="h6">
                                        {itemsData[activeImage].title}
                                    </Typography>
                                    :
                                    ""}
                                <div className={classes.ModalImageInformationDescription}>
                                    {itemsData[activeImage].description ?
                                        <Typography variant="subtitle1" component="span">
                                            {itemsData[activeImage].description}
                                        </Typography>
                                        :
                                        ""}

                                </div>
                            </div>
                            <div className={classes.ModalNavigationButtonContainer}>
                                <Button onClick={handlePreviousButtonClick}>
                                    <ChevronLeftIcon />
                                </Button>
                                <Button onClick={handleNextButtonClick}>
                                    <ChevronRightIcon />
                                </Button>
                            </div>
                        </div>
                    </Fade>
                </Modal>
                : ""}
        </>
    )
}

export { HeaderPart, Services, ItemsPart }