import React from 'react'
import AdminPanel from '../../components/admin'

const itemContainer = "items"
const itemDatabase = "items"

export default function AdminPage() {
    return (
        <AdminPanel itemContainer={itemContainer} itemDatabase={itemDatabase} />
    )
}