import React, { useState, useEffect, useRef } from 'react'

import { Box, Button, Container, Grid, TextField } from '@material-ui/core'
import MaterialTable from 'material-table';

import { storage, firestore } from '../../config/firebase/index'
import { v4 as uuidv4 } from 'uuid'

export default function AdminPanel(props) {
    const { itemContainer, itemDatabase } = props

    // Table state
    const [tableData, setTableData] = useState([]);

    // File Uploader State
    const imageRef = useRef(null)

    useEffect(() => {
        async function getDataFromDatabase() {
            const dataList = await firestore.collection(itemContainer).get()
            const tempList = []

            dataList.forEach(item => {
                tempList.push({ ...item.data(), id: item.id })
            })

            setTableData(tempList)
        }

        getDataFromDatabase()
    }, [itemContainer])

    async function handleFireBaseUpload(filename) {
        const imageAsFile = imageRef.current.files[0]

        if (!imageAsFile) {
            return
        }

        const storageRef = storage.ref(itemContainer)
        const fileRef = storageRef.child(filename)

        try {
            await fileRef.put(imageAsFile)

            const downloadLink = await storage.ref(itemContainer).child(filename).getDownloadURL()

            return { downloadLink: downloadLink, filename: filename }
        } catch (err) {
            console.log(err)
        }
    }

    async function handleFireBaseDelete(filename) {
        if (filename === '') {
            console.error(`you didn't supply any filename to delete`)
            throw new Error("Error")
        }

        const storageRef = storage.ref(itemContainer)
        const fileRef = storageRef.child(filename)

        try {
            await fileRef.delete()

            return true
        } catch (err) {
            console.log(err)
            throw new Error("Dosya silinirken bir sorunla karşılaştık!")
        }
    }

    return (
        <Container maxWidth="lg">
            <Grid container justify="center">
                <Grid item xs={12}>
                    <Box mb={4}>
                        <MaterialTable
                            title="Parça listesi"
                            options={{ pageSize: 10, pageSizeOptions: [10, 25, 50, 100] }}
                            columns={[
                                {
                                    title: 'Başlık', field: 'title', editComponent: props => (
                                        <TextField
                                            type="text"
                                            fullWidth
                                            placeholder={props.columnDef.title}
                                            value={props.value}
                                            onChange={e => props.onChange(e.target.value)}
                                        />
                                    )
                                },
                                {
                                    title: 'Açıklama', field: 'description', editComponent: props => (
                                        <TextField
                                            type="text"
                                            fullWidth
                                            multiline
                                            placeholder={props.columnDef.title}
                                            rowsMax={4}
                                            value={props.value}
                                            onChange={e => props.onChange(e.target.value)}
                                        />
                                    )
                                },
                                {
                                    title: 'Fotoğraf', field: 'photo', editable: 'always',
                                    editComponent: () => (
                                        <div value="photo">
                                            <img id="preview-image" alt="" width="100%" />
                                            <form>
                                                <input
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="raised-button-file"
                                                    type="file"
                                                    ref={imageRef}
                                                    onChange={event => {
                                                        document.getElementById("preview-image").src = window.URL.createObjectURL(imageRef.current.files[0])
                                                    }}
                                                />
                                                <label htmlFor="raised-button-file">
                                                    <Button variant="outlined" component="span" fullWidth>
                                                        Fotoğraf seç
                                                    </Button>
                                                </label>
                                            </form>
                                        </div>
                                    ),
                                    render: rowData =>
                                        !rowData.photo ?
                                            (
                                                <p>Fotoğraf bulunamadı.</p>
                                            )
                                            :
                                            (
                                                <img src={rowData.photo} width="100%" alt="" />
                                            )
                                }
                            ]}
                            data={tableData}
                            localization={{
                                header: {
                                    actions: "Aksiyonlar"
                                },
                                body: {
                                    emptyDataSourceMessage: 'Gösterilecek kayıt yok',
                                    addTooltip: 'Ekle',
                                    editTooltip: 'Düzenle',
                                    deleteTooltip: 'Sil',
                                    editRow: {
                                        deleteText: 'Bu girdiyi silmek istiyor musunuz?'
                                    }
                                },
                                toolbar: {
                                    searchTooltip: 'Arama',
                                    searchPlaceholder: "Ara"
                                },
                                pagination: {
                                    labelRowsSelect: 'satır',
                                    labelDisplayedRows: '{count} satırdan {from}-{to} arası',
                                    firstTooltip: 'İlk Sayfa',
                                    previousTooltip: 'Önceki Sayfa',
                                    nextTooltip: 'Sonraki Sayfa',
                                    lastTooltip: 'Son Sayfa'
                                }
                            }}
                            editable={{
                                onRowAdd: (newData) => new Promise(async (resolve, reject) => {
                                    try {
                                        const uuid = uuidv4()

                                        if (!imageRef.current.files[0]) {
                                            alert("Bir resim yüklemek zorundasınız!")
                                            return reject()
                                        }

                                        const imageData = await handleFireBaseUpload(uuid)
                                        const date = new Date()

                                        newData.photo = imageData.downloadLink
                                        newData.filename = uuid

                                        await firestore.collection(itemDatabase).doc(uuid).set({
                                            ...newData,
                                            createdAt: date,
                                            updatedAt: date
                                        })

                                        newData.id = uuid

                                        setTableData((prevState) => {
                                            const data = [...prevState]
                                            data.push(newData)
                                            return data;
                                        });

                                        resolve()
                                    } catch (err) {
                                        console.log(err)
                                        reject()
                                    }
                                }),
                                onRowUpdate: (newData, oldData) => new Promise(async (resolve, reject) => {
                                    if (oldData) {
                                        try {
                                            const imageData = await handleFireBaseUpload(oldData.filename)

                                            try {
                                                newData.photo = imageData.downloadLink
                                            } catch (err) {
                                                newData.photo = oldData.photo
                                            }

                                            try {
                                                newData.filename = imageData.filename
                                            } catch (err) {
                                                newData.filename = oldData.filename
                                            }

                                            const date = new Date()

                                            await firestore.collection(itemDatabase).doc(oldData.id).update({
                                                ...newData,
                                                updatedAt: date
                                            })

                                            setTableData((prevState) => {
                                                const data = [...prevState];
                                                data[data.indexOf(oldData)] = newData
                                                return data
                                            })

                                            resolve()
                                        } catch (err) {
                                            console.log(err)
                                            reject()
                                        }
                                    }
                                }),
                                onRowDelete: (oldData) => new Promise(async (resolve, reject) => {
                                    try {
                                        await handleFireBaseDelete(oldData.filename)
                                    } catch (err) {
                                        console.log(err)
                                    }

                                    try {
                                        await firestore.collection(itemDatabase).doc(oldData.id).delete()
                                    } catch (err) {
                                        console.log(err)
                                        return reject()
                                    }

                                    setTableData((prevState) => {
                                        const data = [...prevState];
                                        data.splice(data.indexOf(oldData), 1);
                                        return data;
                                    })

                                    resolve()
                                })
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}