import React from 'react'

import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    bigCircle: {
        width: 24,
        height: 24
    },
    timelineContainer: {
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[6],
        borderRadius: 6,
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1)
        }
    }
}))

export default function TimelaneItemContainer(props) {
    const classes = useStyles()
    const { circleColor } = props

    return (
        <TimelineItem>
            <TimelineSeparator>
                <TimelineDot variant="outlined" color={circleColor ? circleColor : "grey"}>
                    <div className={classes.bigCircle} />
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                <div className={classes.timelineContainer}>
                    {props.children}
                </div>
            </TimelineContent>
        </TimelineItem>
    )
}