import React, { useState, useContext } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import UserContext from '../../contexts/user.context'
import { auth } from '../../config/firebase/index'
import { Redirect } from 'react-router-dom'
import { INDEX_ROUTE } from '../../config/routes'

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: `${theme.spacing(8)}px 0`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function LoginPage() {
    const [currentUser] = useContext(UserContext)

    const [userState, setUserState] = useState({
        email: "",
        password: ""
    })

    const [errors, setErrors] = useState({
        main: ""
    })

    const classes = useStyles();

    function handleChange(event) {
        setErrors(state => ({ ...state, main: "" }))
        const { id, value } = event.target

        setUserState(state => ({ ...state, [id]: value }))
    }

    async function handleSubmit(event) {
        event.preventDefault()

        try {
            await auth.signInWithEmailAndPassword(userState.email, userState.password)
        } catch (err) {
            console.log(err)
            setErrors(state => ({ ...state, main: err.message }))
        }
    }

    return (
        <>
            {Object.keys(currentUser).length ? <Redirect to={INDEX_ROUTE} /> : ""}
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Giriş yap
                </Typography>
                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Adresi"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Şifre"
                            type="password"
                            id="password"
                            helperText={errors.main ? errors.main : ""}
                            error={errors.main ? true : false}
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Giriş yap
                    </Button>
                    </form>
                </div>
            </Container>
        </>
    );
}