import React from 'react'
import Timeline from '@material-ui/lab/Timeline';

import { Container, makeStyles, Typography } from '@material-ui/core';

import TimelaneItemContainer from '../../components/about_us/index'
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    MobileOverrides: {
        [theme.breakpoints.down('sm')]: {
            "& .MuiTimeline-root": {
                padding: 0
            },
            "& .MuiTimelineItem-missingOppositeContent:before": {
                display: "none"
            }
        }
    }
}))

export default function AboutUsPage() {
    const classes = useStyles()
    const { t } = useTranslation('common')

    const TimelineList = [
        {
            timeline: t('about_us.1.timeline'),
            description: t('about_us.1.description'),
        },
        {
            timeline: t('about_us.2.timeline'),
            description: t('about_us.2.description'),
            circleColor: "primary"
        },
        {
            timeline: t('about_us.3.timeline'),
            description: t('about_us.3.description'),
        },
        {
            timeline: t('about_us.4.timeline'),
            description: t('about_us.4.description'),
            circleColor: "primary"
        }
    ]

    return (
        <Container maxWidth="md">
            <div className={classes.MobileOverrides}>
                <Timeline align="alternate" >
                    {TimelineList.map(({ timeline, description, circleColor }) => (
                        <TimelaneItemContainer key={timeline} circleColor={circleColor ? circleColor : ""}>
                            <Typography variant="h4" component="h2" gutterBottom>
                                {timeline}
                            </Typography>
                            <Typography variant="body1">
                                {description}
                            </Typography>
                        </TimelaneItemContainer>
                    ))}
                </Timeline>
            </div>
        </Container>
    );
}