import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import { CircularProgress } from '@material-ui/core';

import Wrapper from './components/wrapper'
import IndexPage from './pages/index/index'
import AboutUsPage from './pages/about_us/index'
import LoginPage from './pages/user/login'
import AdminPage from './pages/admin/index'
import { ABOUT_US_ROUTE, ADMIN_ROUTE, INDEX_ROUTE, LOGIN_ROUTE } from './config/routes'
import UserContext from './contexts/user.context'

function App() {
	const [currentUser] = useContext(UserContext)

	return (
		<Router>
			<Wrapper>
				<Suspense fallback={<CircularProgress />}>
					<Switch>
						<Route path={INDEX_ROUTE} exact component={IndexPage} />
						<Route path={LOGIN_ROUTE} exact component={LoginPage} />
						<Route path={ABOUT_US_ROUTE} exact component={AboutUsPage} />
						<Route path={ADMIN_ROUTE} exact render={() => {
							return (
								<>
									{Object.keys(currentUser).length ? "" : <Redirect to={INDEX_ROUTE} />}
									<AdminPage />
								</>
							)
						}} />
					</Switch>
				</Suspense>
			</Wrapper>
		</Router>
	);
}

export default App;
