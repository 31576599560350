import React, { useContext, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import clsx from 'clsx'
import { fade, makeStyles } from '@material-ui/core/styles'

import { auth } from '../../config/firebase/index'
import UserContext from '../../contexts/user.context'
import { INDEX_ROUTE, ABOUT_US_ROUTE, LOGIN_ROUTE } from '../../config/routes'
import { grey } from '@material-ui/core/colors'
import { SITE_LOGO } from '../../config/images'
import { Box, Grid, IconButton, Typography } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import RoomIcon from '@material-ui/icons/Room'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
    SiteLogo: {
        height: 56,
        margin: theme.spacing(1, 0)
    },
    InformationContainer: {
        display: "flex",
        padding: theme.spacing(0, 4),
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    InformationItem: {
        padding: theme.spacing(1),
        backgroundColor: "#000",
        color: "#fff",
        marginRight: theme.spacing(1),
        "& svg": {
            marginRight: theme.spacing(1)
        },
        "& h6": {
            fontSize: ".8rem"
        },
        "& span": {
            fontSize: ".9rem"
        }
    },
    MainContainer: {
        display: "flex",
        padding: theme.spacing(2, 4),
        justifyContent: "flex-end",
        alignItems: "center",
        "& a": {
            color: grey[600],
            textDecoration: "none"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "initial",
            padding: theme.spacing(2)
        }
    },
    LinkContainer: {
        fontWeight: "bold",
        fontSize: "1rem",
        padding: theme.spacing(1)
    },
    DesktopLinks: {
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    MobileLinks: {
        display: "none",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            display: "flex",
            justifyContent: "space-between"
        }
    },
    SidebarContainer: {
        display: "flex",
        flexDirection: "column",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: fade("#000", 0.7),
        "& $LinkContainer": {
            color: "#fff"
        }
    },
    SidebarContainerActive: {
        width: "100%",
        height: "100%"
    },
    SidebarContainerClosed: {
        width: 0,
    }
}));

function MenuButtons(props) {
    const [currentUser] = useContext(UserContext)
    const { t } = useTranslation('common')

    const classes = useStyles();
    return (
        <>
            <Link to={INDEX_ROUTE} className={classes.LinkContainer} onClick={props.handleSidebarClose ? props.handleSidebarClose : undefined}>
                {t("header.home")}
            </Link>
            <Link to={ABOUT_US_ROUTE} className={classes.LinkContainer} onClick={props.handleSidebarClose ? props.handleSidebarClose : undefined}>
                {t("header.about_us")}
            </Link>
            {Object.keys(currentUser).length ?
                <>
                    <Link to="/admin" className={classes.LinkContainer} onClick={props.handleSidebarClose ? props.handleSidebarClose : undefined}>
                        {t("header.admin_panel")}
                    </Link>
                    <Link to="/#" onClick={() => {
                        auth.signOut()
                        if (props.handleSidebarClose) props.handleSidebarClose()
                    }} className={classes.LinkContainer}>
                        {t("header.logout")}
                    </Link>
                </>
                :
                <Link to={LOGIN_ROUTE} className={classes.LinkContainer} onClick={props.handleSidebarClose ? props.handleSidebarClose : undefined}>
                    {t("header.login")}
                </Link>
            }
        </>
    )
}

function InformationBox() {
    const classes = useStyles()
    const { t } = useTranslation('common')

    return (
        <div className={classes.InformationContainer}>
            <Link to={INDEX_ROUTE}>
                <img src={SITE_LOGO} alt="" className={classes.SiteLogo} />
            </Link>
            <Grid container justify="flex-end" style={{ height: "100%" }}>
                <Grid item className={classes.InformationItem}>
                    <Box display="flex" alignItems="center">
                        <MailIcon />
                        <div>
                            <Typography variant="h6">{t("header.reach_us")}:</Typography>
                            <Typography variant="body1" component="span" className={classes.MetadataBox}>
                                <a href={`mailto:${process.env.REACT_APP_EMAIL_ADRESS}`} style={{ color: "inherit", textDecoration: "none" }}>
                                    {process.env.REACT_APP_EMAIL_ADRESS}
                                </a>
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                <Grid item className={classes.InformationItem}>
                    <Box display="flex" alignItems="center">
                        <PhoneIcon />
                        <div>
                            <Typography variant="h6">{t("header.reach_us")}:</Typography>
                            <Typography variant="body1" component="span" className={classes.MetadataBox}>
                                {process.env.REACT_APP_PHONE_NUMBER}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                <Grid item className={classes.InformationItem}>
                    <Box display="flex" alignItems="center">
                        <RoomIcon />
                        <div>
                            <Typography variant="h6">{t("header.reach_us")}:</Typography>
                            <Typography variant="body1" component="span" className={classes.MetadataBox}>
                                {process.env.REACT_APP_ADRESS}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}

export default function ButtonAppBar() {
    const [sidebarStatus, setSidebarStatus] = useState(false)
    const classes = useStyles()

    useEffect(() => {
        document.body.style.overflow = sidebarStatus ? "hidden" : "initial"
    }, [sidebarStatus])

    function handleSidebarChange() {
        setSidebarStatus(state => !state)
    }

    function handleSidebarClose() {
        setSidebarStatus(false)
    }

    return (
        <header>
            <InformationBox />
            <div className={classes.MainContainer}>
                <div className={classes.DesktopLinks}>
                    <MenuButtons />
                </div>
                <div className={classes.MobileLinks}>
                    <Link to={INDEX_ROUTE}>
                        <img src={SITE_LOGO} alt="" className={classes.SiteLogo} />
                    </Link>
                    <IconButton
                        color="inherit"
                        aria-label="Open/close drawer"
                        onClick={handleSidebarChange}
                        edge="start"
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </div>
                {sidebarStatus ?
                    <div className={clsx(classes.SidebarContainer, {
                        [classes.SidebarContainerActive]: sidebarStatus,
                        [classes.SidebarContainerClosed]: !sidebarStatus
                    })} onClick={handleSidebarClose}>
                        <MenuButtons handleSidebarClose={handleSidebarClose} />
                    </div>
                    : ""}
            </div>
        </header>
    );
}