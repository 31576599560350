import firebase from 'firebase/app'
import firebaseConfig from './firebase.config'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/firestore'

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const auth = firebase.auth()
const storage = firebase.storage()
const firestore = firebase.firestore()

export { firebase, auth, storage, firestore }