import React from 'react'
import { HeaderPart, ItemsPart, Services } from '../../components/index'

export default function IndexPage() {
    return (
        <>
            <HeaderPart />
            <Services />
            <ItemsPart />
        </>
    )
}