import React from 'react'

import { Grid, makeStyles, Typography } from '@material-ui/core'
import RoomIcon from '@material-ui/icons/Room'
import MailIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'

import { SITE_LOGO } from '../../config/images'
import { grey } from '@material-ui/core/colors'
import { Link } from 'react-router-dom'
import { INDEX_ROUTE } from '../../config/routes'
import { useTranslation } from 'react-i18next'

import countryFlagTR from 'flag-icon-css/flags/4x3/tr.svg'
import countryFlagEN from 'flag-icon-css/flags/4x3/us.svg'
import i18next from '../../config/i18n'

const useStyles = makeStyles(theme => ({
    MainContainer: {
        backgroundColor: grey[900],
        marginTop: theme.spacing(4),
        color: "#fff"
    },
    GridContainer: {
        maxWidth: 1400,
        margin: "0 auto",
        padding: theme.spacing(10, 2),
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            padding: `${theme.spacing(6)}px 10px`
        }
    },
    InformationBox: {
        position: "relative"
    },
    SiteLogo: {
        width: 120
    },
    MetadataBox: {
        display: "flex",
        alignItems: "center",
        padding: `${theme.spacing(1)}px 0`,
        "& svg": {
            marginRight: theme.spacing(1)
        }
    },
    LanguageFlags: {
        "& img": {
            cursor: "pointer",
            marginRight: theme.spacing(1)
        }
    },
    CopyrightContainer: {
        backgroundColor: grey["A700"],
        padding: theme.spacing(4),
        color: "#fff",
        "& a": {
            color: "inherit",
            textDecoration: "none"
        }
    },
}))

function Footer(props) {
    const classes = useStyles()
    const { t } = useTranslation('common')

    function LanguageChange(language) {
        i18next.changeLanguage(language)
    }

    return (
        <>
            <footer>
                <div className={classes.MainContainer}>
                    <div className={classes.GridContainer}>
                        <Grid container justify="center" spacing={2}>
                            <Grid item xs={12} md={6} className={classes.InformationBox}>
                                <img src={SITE_LOGO} alt="" className={classes.SiteLogo} />
                                <Typography variant="body1" gutterBottom>
                                    {t('site.title')}
                                </Typography>
                                <Typography variant="body1" className={classes.MetadataBox}>
                                    <MailIcon /> <a href={`mailto:${process.env.REACT_APP_EMAIL_ADRESS}`} style={{ color: "inherit", textDecoration: "none" }}>
                                        {process.env.REACT_APP_EMAIL_ADRESS}
                                    </a>
                                </Typography>
                                <Typography variant="body1" className={classes.MetadataBox}>
                                    <PhoneIcon /> {process.env.REACT_APP_PHONE_NUMBER}
                                </Typography>
                                <Typography variant="body1" className={classes.MetadataBox} gutterBottom>
                                    <RoomIcon /> {process.env.REACT_APP_ADRESS}
                                </Typography>
                                <div className={classes.LanguageFlags}>
                                    <img src={countryFlagTR} height="21px" alt="" onClick={() => LanguageChange("tr")} />
                                    <img src={countryFlagEN} height="21px" alt="" onClick={() => LanguageChange("en")} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className={classes.InformationBox}>
                                <Typography variant="body1">
                                    {t('site.description')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className={classes.CopyrightContainer}>
                    <Typography variant="body2" align="center">
                        © {new Date().getFullYear()} - <Link to={INDEX_ROUTE}>{t('site.title')}</Link>
                    </Typography>
                </div>
            </footer>
        </>
    )
}

export default Footer