import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { ThemeProvider } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './config/theme/index'
import i18next from './config/i18n'

import { auth } from './config/firebase/index'
import UserContext from './contexts/user.context'

import './index.css'
import { I18nextProvider } from 'react-i18next'

function Mount() {
  const [currentUser, setCurrentUser] = useState({})

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user)
        setCurrentUser(user)
      else
        setCurrentUser({})
    })
  }, [])

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <UserContext.Provider value={[currentUser, setCurrentUser]}>
          <I18nextProvider i18n={i18next}>
            <App />
          </I18nextProvider>
        </UserContext.Provider>
      </ThemeProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <Mount />,
  document.getElementById('root')
);
