import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector';

// Import locale files
import common_tr from '../locales/tr/common.json'
import common_en from '../locales/en/common.json'

const resources = {
    tr: {
        common: common_tr
    },
    en: {
        common: common_en
    }
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        useCookie: true,
        debug: process.env.NODE_ENV === "development" ? true : false,
        logging: process.env.NODE_ENV === "development" ? true : false,
        supportedLngs: ["en", "tr"],
        fallbackLng: "tr"
    })

export default i18next;